import { Component } from '@angular/core';
import { DataService } from '../services/data.service';
import { Globals } from '../services/globals';

const EMAIL_REG_EXP  = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class FoodComponent {

  email: string = "";

  constructor(private dataService: DataService, public globals: Globals) { }

  onSubmit() {
    // Validate again
    if(this.email !== "" && this.email.match(EMAIL_REG_EXP)){
      let payload = {
        email: this.email,
        submissionDate: new Date().toLocaleString()
      } 
      try{
        this.dataService.createMessage(payload);
        window.open('https://bringfood.gr');
      }catch (err) {
        console.log(err);
      }
       
    }
  }

}
