<section > 
  <div class="header">
    <h1 class="display-2 text-white font-weight-bolder">
    Custom e-commerce applications</h1>
    <p class="text-gray display-4 mt-2">{{'fast' | translate : globals.getLanguage()}}</p>  
  </div>
  <form ngNoForm class="email-container" onsubmit="return false;">            
    <input name="email" placeholder="Email address" required type="email" 
      value="" class="big-input" [(ngModel)]="email">
    <button mat-raised-button matSuffix class="rounded" type="submit" 
      color="warn" class="big-button" (click)="onSubmit()">
      {{'watch' | translate : globals.getLanguage()}}&nbsp;Demo
    </button>
  </form>
  <div class="img-container">
    <img   
    width="400"
    alt="AppDate Logo"
    src="../../assets/images/woo.png"        
    /> 
    <img   
    width="200"
    alt="AppDate Logo"
    src="../../assets/images/mobile-fireshopW200.png"        
    /> 
  </div>
  <div class="header mt-5">
    <h2 class="text-white font-weight-bolder display-3">
    Web e-shop + mobile e-shop</h2>
    <h3 class="text-gray mt-2 display-5">{{'addMobile' | translate : globals.getLanguage()}}</h3>  
  </div>
  <div class="super-row">
  <div class="space1"></div>
  <div class="mt-5 row">
    <div class="mb-3 col-lg-6">
      <div class="icon-box">
        <div class="d-flex px-3">
          <div class="icon-container">
            <div class="icon feature-icon">
              🤘</div>
          </div>
          <div class="pl-4 wordind-container">
            <h5 class="icon-box-title text-white font-weight-bolder">{{'attract' | translate : globals.getLanguage()}}</h5>
            <p class="icon-box-text text-light-gray">
              {{'customers' | translate : globals.getLanguage()}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 col-lg-6">
      <div class="icon-box"><div class="d-flex px-3">
        <div>
          <div class="icon feature-icon">🕐</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'loading' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'instant' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon">💰</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'increase' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'takeAdvantage' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon">📝</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'adaptability' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'enterProducts' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">📢</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Promote&nbsp;your&nbsp;brand</h5>
          <p class="icon-box-text text-light-gray">{{'domain' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">🔎</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'filters' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'catSearch' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">⭐</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'reviews' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'reviewsDet' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">🔌</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'connect' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'connectDet' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">📈</div>
        </div><div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Analytics</h5>
          <p class="icon-box-text text-light-gray">{{'analytics' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">🔒</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'security' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'ssl' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">💶</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'pricing' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'inTheShopPriceDetail' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">💳</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">{{'fin' | translate : globals.getLanguage()}}</h5>
          <p class="icon-box-text text-light-gray">{{'gov' | translate : globals.getLanguage()}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="space1"></div>
</div>
<div class="header mt-5">
  <h2 class="text-white font-weight-bolder display-3">
    {{'presentation' | translate : globals.getLanguage()}}</h2>
  <h3 class="text-gray mt-2 display-5">{{'presDetail' | translate : globals.getLanguage()}}</h3>  
</div>
<form ngNoForm class="email-container" onsubmit="return false;">            
  <input name="email" placeholder="Email address" required type="email" 
    value="" class="big-input" [(ngModel)]="email">
  <button mat-raised-button matSuffix class="rounded" type="submit" 
    color="warn" class="big-button" (click)="onSubmit()">
    {{'watch' | translate : globals.getLanguage()}}&nbsp;Demo
  </button>
</form>
</section>
