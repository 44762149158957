import { Component, OnInit} from '@angular/core';
import { AuthService } from './services/auth.service';
import{Router, NavigationEnd} from '@angular/router';
import { Globals } from './services/globals';
import {trigger,state,style,animate,transition} from '@angular/animations';


declare let gtag: Function;

const defaultEmail = "defaultUser@gmail.com";
const defaultPassword = ")j~nKj/,N}N6,8&cVVV#G!=F*y";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('hamburguerX', [
      /*
        state hamburguer => is the regular 3 lines style.
        states topX, hide, and bottomX => used to style the X element
      */
      state('hamburguer', style({})),
      // style top bar to create the router
      state(
        'topX',
        style({
          transform: 'rotate(45deg)',
          transformOrigin: 'left',
          margin: '3px',
        })
      ),
      // hides element when create the X (used in the middle bar)
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      // style bottom bar to create the X
      state(
        'bottomX',
        style({
          transform: 'rotate(-45deg)',
          transformOrigin: 'left',
          margin: '3px',
        })
      ),
      state(
        'MenuOut',
        style({
          left: '0px',
          transformOrigin: 'right'
        })
      ),
      transition('* => *', [
        animate('0.2s'), // controls animation speed
      ]),
    ]),
  ],
})

export class AppComponent implements OnInit{   

  languages = [
    { code: 'el', name: 'Ελληνικά', image: 'language-2' },
    { code: 'en', name: 'English', image: 'language-1' }
  ]

  currentLanguage: string = 'EL';

  constructor(private authService: AuthService, public router: Router, 
    public globals: Globals){   
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){      
        gtag('config', 'G-X5D7D3SDWD',
          {
            'page_title' : event.urlAfterRedirects.slice(1),
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
  )}

  isMenuOpen = false;
  toggleMenu(): void{
    this.isMenuOpen = !this.isMenuOpen;
  }

  async ngOnInit(){
    const auth = await this.authService.login(defaultEmail, defaultPassword);
    
  }

  changeLang(language: string) {
    this.globals.setLanguage(language);
    this.currentLanguage = language.toUpperCase();
}
}

