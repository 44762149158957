import { Injectable } from '@angular/core';
import { Globals } from './globals';
import * as greek from '../../assets/i18n/el';
import * as english from '../../assets/i18n/en';

/**
 * Abstraction layer for auth. Nice to have when things get more complicated.
 */
 @Injectable({
    providedIn: 'root',
  })
  export class TranslationService {

  constructor(private globals: Globals) {}
 

  public translate = (key: string): string => { 
    return this.globals.getLanguage() === 'el' ? greek.general[key] : english.general[key];
  }

  

}
