<section>
  <div class="img-container">
    <img   
    width="{{imageWidth}}"    
    alt="AppDate Logo"
    src="{{image}}"        
    /> 
  </div>
  <div class="data-container">
      <p *ngIf="data1">✔️&nbsp;{{data1}}</p>
      <p *ngIf="data2">✔️&nbsp;{{data2}}</p>
      <p *ngIf="data3">✔️&nbsp;{{data3}}</p>
      <p *ngIf="data4">✔️&nbsp;{{data4}}</p>
      <p *ngIf="data5">✔️&nbsp;{{data5}}</p>
  </div>
</section>
