<div class="isol-container">
  <h1 class="title">ΙΣΟΛΟΓΙΣΜΟΣ 2019</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_APP_2019.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1200px"
    /> 
  </div>
  <hr/>
  <h1 class="title">ΙΣΟΛΟΓΙΣΜΟΣ 2020</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL_APP_2020.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1200px"
    /> 
  </div>
  <hr/>
  <h1 class="title">ΙΣΟΛΟΓΙΣΜΟΣ 2021</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL-2021.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1200px"
    /> 
  </div>
  <h1 class="title">ΙΣΟΛΟΓΙΣΜΟΣ 2022</h1>
  <div class="imgElement">
    <embed
      src="../../assets/images/ISOL-2022.pdf"
      type="application/pdf"
      frameBorder="0"
      scrolling="auto"
      height="700px"
      width="1200px"
    /> 
  </div>
  <hr/>
</div>