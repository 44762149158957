
<div class="container-outter">
    <div class="container">
        <div class="heading-section">
            <div class="heading-title">
              <h1 class="font-darkblue">{{'policy_title' | translate : globals.getLanguage()}}</h1>
              <p class="font-grey">{{'policy_subtitle' | translate : globals.getLanguage()}}</p>
            </div>
        </div>
    </div>
  </div>



<div class="container">
    <div class="article-text space-bottom">
        <h2>{{'policy_heading' | translate : globals.getLanguage()}}</h2>
        <p> {{'policy_paragraph' | translate : globals.getLanguage()}}</p>
        <h3>{{'terms_heading_2' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_2' | translate : globals.getLanguage()}}</p>
        <ul>
            <li>{{'terms_par_2_list_1' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_2_list_2' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_2_list_3' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_2_list_4' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_2_list_5' | translate : globals.getLanguage()}}</li>
        </ul>
        <h3>{{'terms_heading_3' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_3' | translate : globals.getLanguage()}}</p>
        <h3>{{'terms_heading_4' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_4' | translate : globals.getLanguage()}}</p>
        <ul>
            <li>{{'terms_par_4_list_1' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_4_list_2' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_4_list_3' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_4_list_4' | translate : globals.getLanguage()}}</li>
        </ul>
        <p>{{'terms_paragraph_4_2' | translate : globals.getLanguage()}}</p>
        <ul>
            <li>{{'terms_par_4_2_list_1' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_4_2_list_2' | translate : globals.getLanguage()}}</li>
        </ul>
        <h3>{{'terms_heading_5' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_5' | translate : globals.getLanguage()}}</p>
        <ul>
            <li>{{'terms_par_5_list_1' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_2' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_3' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_4' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_5' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_6' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_7' | translate : globals.getLanguage()}}</li>
            <li>{{'terms_par_5_list_8' | translate : globals.getLanguage()}}</li>
        </ul>
        <h3>{{'terms_heading_6' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_6' | translate : globals.getLanguage()}}</p>
        <h3>{{'terms_heading_7' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_7' | translate : globals.getLanguage()}}</p>
        <ul class="li-with-par">
            <li>{{'terms_par_7_list_1_heading_1' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_1' | translate : globals.getLanguage()}}</p>
            <li>{{'terms_par_7_list_1_heading_2' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_2' | translate : globals.getLanguage()}}</p>
            <li>{{'terms_par_7_list_1_heading_3' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_3' | translate : globals.getLanguage()}}</p>
            <li>{{'terms_par_7_list_1_heading_4' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_4' | translate : globals.getLanguage()}}</p>
            <ul class="sub-ul">
                <li>{{'terms_par_7_list_2_heading_4_ul_1' | translate : globals.getLanguage()}}</li>
                <li>{{'terms_par_7_list_2_heading_4_ul_2' | translate : globals.getLanguage()}}</li>
                <li>{{'terms_par_7_list_2_heading_4_ul_3' | translate : globals.getLanguage()}}</li>
            </ul>
            <li>{{'terms_par_7_list_1_heading_5' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_5' | translate : globals.getLanguage()}}</p>
            <li>{{'terms_par_7_list_1_heading_6' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_6' | translate : globals.getLanguage()}}</p>
            <li>{{'terms_par_7_list_1_heading_7' | translate : globals.getLanguage()}}</li>
            <p>{{'terms_par_7_list_2_paragraph_7' | translate : globals.getLanguage()}}</p>
        </ul>
        <h3>{{'terms_heading_8' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_8' | translate : globals.getLanguage()}}</p>
        <h3>{{'terms_heading_9' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-smst"></div>
        <p>{{'terms_paragraph_9' | translate : globals.getLanguage()}}</p>
        <h2>{{'terms_heading' | translate : globals.getLanguage()}}</h2>
        <p>{{'terms_paragraph_pre_10' | translate : globals.getLanguage()}}</p>
        <h3>{{'terms_heading_10' | translate : globals.getLanguage()}}</h3>
        <p>{{'terms_paragraph_10' | translate : globals.getLanguage()}}</p>
    </div>
</div>
