import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { AppsComponent } from './apps/apps.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { EshopComponent } from './eshop/eshop.component';
import { InTheShopComponent } from './intheshop/intheshop.component';
import { FoodComponent } from './food/food.component';
import { MobileComponent } from './mobile/mobile.component';
import { ProductComponent } from './product/product.component';
import { PolicyComponent } from './policy/policy.component';
import { Feathers } from './services/feathers.service';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { FinancialsComponent } from './financials/financials.component';
import { DropdownDirective } from '../directives/dropdown.directive';
import { IconComponent } from 'src/components/icon/icon.component';
import { TranslationPipe } from './pipes/translation.pipe';
import { BookingSystemComponent } from './booking-system/booking-system.component';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactusComponent,
    AppsComponent,
    EshopComponent,
    InTheShopComponent,
    FoodComponent,
    MobileComponent,
    ProductComponent,
    PolicyComponent,
    FinancialsComponent,
    DropdownDirective,
    IconComponent,
    TranslationPipe,
    BookingSystemComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
    MatTabsModule
  ],
  providers: [   
    Feathers,
    DataService,   
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
