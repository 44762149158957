import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Globals } from '../services/globals';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {
  
  messageForm = this.fb.group({
    company: null,
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null, Validators.required],    
    email: [null, Validators.compose([Validators.required, Validators.email])], 
    message: [null, Validators.compose([
      Validators.required, Validators.minLength(5), Validators.maxLength(700)])
    ],
    interested: ['eshop', Validators.required]
  });

 

  constructor(private fb: FormBuilder, private dataService: DataService, public globals: Globals) {}


  onSubmit() {
    let payload =  this.messageForm.value;
    Object.assign(payload, {submissionDate: new Date().toLocaleString()})    
    try{
      this.dataService.createMessage(payload);
      alert('Thanks!');
    }catch (err) {
      console.log(err);
    }  
  }
}
