 import { Injectable } from '@angular/core';
/*
 import * as feathersRx from 'feathers-reactive';
 import * as io from 'socket.io-client';
 
 import feathers from '@feathersjs/feathers';
 import feathersSocketIOClient from '@feathersjs/socketio-client';
 import feathersAuthClient2 from '@feathersjs/authentication-client';
 
*/

//import * as rx from 'feathers-reactive';
//import * as feathersRx from 'feathers-reactive';
//import rx from 'feathers-reactive'
import * as io from 'socket.io-client'; 
import feathers from '@feathersjs/feathers';
//import feathersSocketIOClient from '@feathersjs/socketio-client';
import socketio from '@feathersjs/socketio-client';
//import auth from '@feathersjs/authentication-client';

 /**
  * Simple wrapper for feathers
  */
 @Injectable()
 export class Feathers {
   public _feathers: any = feathers();                     
   private _socket = io('https://sites.appdate.gr');      
 
   private feathersAuthClient = require('@feathersjs/authentication-client').default;
 
   constructor() {
     this._feathers
     // .configure(feathersSocketIOClient(this._socket))  // add socket.io plugin
       .configure(socketio(this._socket))  // add socket.io plugin
       .configure(this.feathersAuthClient({                   // add authentication plugin
         storage: window.localStorage
       }));
   }
 
   // expose services
   public service(name: string) {
     return this._feathers.service(name);
   }
 
   // expose authentication
   public authenticate(credentials?): Promise<any> {
     return this._feathers.authenticate(credentials);
   }
 
   // expose logout
   public logout() {
     return this._feathers.logout();
   }
 }