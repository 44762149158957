import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translate'
})
export class TranslationPipe implements PipeTransform {

  constructor(private translationService: TranslationService){    
  }

  transform(value: string, lang: string): string { //I added lang for change detection
    return this.translationService.translate(value);
  }

}