<div class="container-outter">
    <div class="container">
        <div class="heading-section">
            <div class="heading-title">
              <h1 class="font-darkblue">{{'booking_Heading_Title' | translate : globals.getLanguage()}}</h1>
              <p class="font-grey">{{'booking_Heading_Paragraph' | translate : globals.getLanguage()}}</p>
                <div class="aside-divider">
                  <div class="divider"></div>
                    <p class="font-grey">{{'booking_Heading_Paragraph_2' | translate : globals.getLanguage()}}</p>
                  <div class="divider"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <div class="container-outter">
  <div class="container">
    <div class="container-inner">
      <div class="column col-1">
        <div class="features-content">
          <h3><span class="font-lightblue">01. </span>{{'booking_Title_1' | translate : globals.getLanguage()}}</h3>
          <div class="left-divider-sm"></div>
          <ul class="features-list">
            <li>
              <h4>{{'booking_Heading_Paragraph_1_1' | translate : globals.getLanguage()}}</h4>
              <p>{{'booking_Paragraph_1_1' | translate : globals.getLanguage()}}</p>
            </li>
            <li>
              <h4>{{'booking_Heading_Paragraph_1_2' | translate : globals.getLanguage()}}</h4>
              <p>{{'booking_Paragraph_1_2' | translate : globals.getLanguage()}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="column col-2">
        <div class="feature-img features-image-right">
          <img alt="booking-img-1" src="../../assets/images/eshop-making-graph1.png"/>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  <div class="container-outter">
    <div class="container">
      <div class="container-inner">
        <div class="column col-2">
          <div class="feature-img features-image-left">
            <img alt="booking-img-2" src="../../assets/images/eshop-making-graph2-safe-payments.png"/>
          </div>
        </div>
        <div class="column col-1">
          <div class="features-content">
            <h3><span class="font-lightblue">02. </span>{{'booking_Title_2' | translate : globals.getLanguage()}}</h3>
            <div class="left-divider-sm"></div>
            <ul class="features-list">
              <li>
                <h4>{{'booking_Heading_Paragraph_2_1' | translate : globals.getLanguage()}}</h4>
                <p>{{'booking_Paragraph_2_1' | translate : globals.getLanguage()}}</p>
              </li>
              <li>
                <h4>{{'booking_Heading_Paragraph_2_2' | translate : globals.getLanguage()}}</h4>
                <p>{{'booking_Paragraph_2_2' | translate : globals.getLanguage()}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  
    <div class="container-outter">
      <div class="container">
        <div class="container-inner">
          <div class="column col-1">
            <div class="features-content">
              <h3><span class="font-lightblue">03. </span>{{'booking_Title_3' | translate : globals.getLanguage()}}</h3>
              <div class="left-divider-sm"></div>
              <ul class="features-list">
                <li>
                  <h4>{{'booking_Heading_Paragraph_3_1' | translate : globals.getLanguage()}}</h4>
                  <p>{{'booking_Paragraph_3_1' | translate : globals.getLanguage()}}</p>
                </li>
                <li>
                  <h4>{{'booking_Heading_Paragraph_3_2' | translate : globals.getLanguage()}}</h4>
                  <p>{{'booking_Paragraph_3_2' | translate : globals.getLanguage()}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="column col-2">
            <div class="feature-img features-image-right">
              <img alt="booking-img-3" src="../../assets\images\booking-rental-site-platforms.png"/>

            </div>
          </div>
        </div>
      </div>
      </div>
  
      <div class="container-outter">
        <div class="container">
          <div class="container-inner">
            <div class="column col-2">
              <div class="feature-img features-image-left">
                <img alt="mobile-responsive" src="../../assets\images\booking-mobile-responsive.png"/>
              </div>
            </div>
            <div class="column col-1">
              <div class="features-content">
                <h3><span class="font-lightblue">04. </span>{{'booking_Title_4' | translate : globals.getLanguage()}}</h3>
                <div class="left-divider-sm"></div>
                <ul class="features-list">
                  <li>
                    <h4>{{'booking_Heading_Paragraph_4_1' | translate : globals.getLanguage()}}</h4>
                    <p>{{'booking_Paragraph_4_1' | translate : globals.getLanguage()}}</p>
                  </li>
                  <li>
                    <h4>{{'booking_Heading_Paragraph_4_2' | translate : globals.getLanguage()}}</h4>
                    <p>{{'booking_Paragraph_4_2' | translate : globals.getLanguage()}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
      
        <div class="container-outter">
          <div class="container">
            <div class="container-inner">
              <div class="column col-1">
                <div class="features-content">
                  <h3><span class="font-lightblue">05. </span>{{'booking_Title_5' | translate : globals.getLanguage()}}</h3>
                  <div class="left-divider-sm"></div>
                  <ul class="features-list">
                    <li>
                      <h4>{{'booking_Heading_Paragraph_5_1' | translate : globals.getLanguage()}}</h4>
                      <p>{{'booking_Paragraph_5_1' | translate : globals.getLanguage()}}</p>
                    </li>
                    <li>
                      <h4>{{'booking_Heading_Paragraph_5_2' | translate : globals.getLanguage()}}</h4>
                      <p>{{'booking_Paragraph_5_2' | translate : globals.getLanguage()}}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="column col-2">
                <div class="feature-img features-image-right">
                  <img alt="booking-img-4" src="../../assets\images\booking-seo-site.png"/>
                </div>
              </div>
            </div>
          </div>
          </div>
  
  
  <div class="container-outter project-section">
    <div class="container">
      <div class="space-bottom">
  
        <!-- <div class="project-heading-section">
          <div class="heading-title">
            <div class="project-title">
              <h2>{{'booking_Project_Heading' | translate : globals.getLanguage()}}</h2>
              <p>{{'booking_Project_Paragraph' | translate : globals.getLanguage()}}</p>
            </div>
          </div>
        </div> -->
  
        <!-- <div class="column-container">
  
          <div class="column-box">
            <div class="project-wrapper">
              <a href="https://coveassos.gr/" target="_blank" class="white-box">
                  <img class="portfolio-grid-img" src="/assets/images/Angelo-Zafiri.gr.jpg" alt="AngeloZafiri.gr">
                  <div class="portfolio-box-content">
                    <h4>CoveAssos.gr</h4>
                  </div>
              </a>
              <img src="/assets/images/gray-square.png" class="square-topleft"/>
            </div>
          </div>
  
          </div> -->
  
          <div class="flex-center">
            <a routerLink="/contactus" routerLinkActive="router-link-active" class="btn project-btn base-transition">{{'eshop_Project_Button' | translate : globals.getLanguage()}}</a>
          </div>
  
      </div>
    </div>
  </div>
  
  