<div class="container-outter">
  <div class="container">
      <div class="heading-section">
          <div class="heading-title">
            <h1 class="font-darkblue">{{'apps_header_title' | translate : globals.getLanguage()}}</h1>
            <p class="font-grey">{{'apps_header_subtitle' | translate : globals.getLanguage()}}</p>
          </div>
      </div>
  </div>
</div>

<div class="app-content space-bottom">
  <div class="grid-container">
    <mat-grid-list cols="{{columnNumber}}" rowHeight="19rem" (window:resize)="onResize($event)">
      <mat-grid-tile  [colspan]="1" [rowspan]="1">
        <a routerLink="/eshop">
        <mat-card class="dashboard-card">
          <div class="mat-card-header">
            <div class="mat-card-title">
              Standard e-shop (WooCommerce)        
            </div>
          </div>
          <mat-card-content class="dashboard-card-content">
            <app-product 
              data1="{{'mob_responsive' | translate : globals.getLanguage()}}" 
              data2="{{'sub' | translate : globals.getLanguage()}}"
              data3="{{'var_features' | translate : globals.getLanguage()}}"
              data4="{{'search' | translate : globals.getLanguage()}}"
              data5="{{'priceA' | translate : globals.getLanguage()}}"
              image="../../assets/images/standard-eshop-internal.jpg">
            </app-product>
          </mat-card-content>
        </mat-card>
        </a>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <a routerLink="/booking-system">
        <mat-card class="dashboard-card">
          <div class="mat-card-header">
            <div class="mat-card-title">
              {{'rental_title_app' | translate : globals.getLanguage()}}            
            </div>
          </div>
          <mat-card-content class="dashboard-card-content">
            <app-product 
              data1="{{'mob_responsive' | translate : globals.getLanguage()}}" 
              data2="{{'booking_sychronize' | translate : globals.getLanguage()}}"
              data3="{{'booking_system' | translate : globals.getLanguage()}}"
              data4="{{'contact_form' | translate : globals.getLanguage()}}"
              data5="{{'priceB' | translate : globals.getLanguage()}}"
              image="../../assets\images\hotel-rental-booking-site.png">
            </app-product>
          </mat-card-content>
        </mat-card>
        </a>
      </mat-grid-tile>
    
    <mat-grid-tile  [colspan]="1" [rowspan]="1">
      <a routerLink="/intheshop">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            {{'custom_eshop_title_app' | translate : globals.getLanguage()}}     
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
              data1="{{'mob_responsive' | translate : globals.getLanguage()}}" 
              data2="{{'cat' | translate : globals.getLanguage()}}"
              data3="{{'var_features' | translate : globals.getLanguage()}}"
              data4="{{'search' | translate : globals.getLanguage()}}"
              data5="{{'priceC' | translate : globals.getLanguage()}}"
              image="../../assets/images/custom-eshop.jpg">
            </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
    
    <mat-grid-tile  [colspan]="1" [rowspan]="1">
      <a routerLink="/intheshop">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            {{'custom_eshop_title_app_2' | translate : globals.getLanguage()}}         
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
            data1="Android + iOS {{'apps' | translate : globals.getLanguage()}}" 
            data2="{{'same' | translate : globals.getLanguage()}}"
            data3="{{'dashboard' | translate : globals.getLanguage()}}"
            data4="{{'push_notifications' | translate : globals.getLanguage()}}"
            data5="{{'priceD' | translate : globals.getLanguage()}}"
            image="../../assets/images/custom-eshop-2.jpg">
        </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile  [colspan]="1" [rowspan]="1">
      <a routerLink="/food">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            {{'food_title_app' | translate : globals.getLanguage()}}         
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
              data1="{{'mob_responsive' | translate : globals.getLanguage()}}" 
              data2="{{'sub' | translate : globals.getLanguage()}}"
              data3="{{'var_features' | translate : globals.getLanguage()}}"
              data4="{{'search' | translate : globals.getLanguage()}}"
              data5="{{'priceE' | translate : globals.getLanguage()}}"
              image="../../assets/images/bringfood.jpg">
            </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <a routerLink="/food">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            {{'food_title_app_2' | translate : globals.getLanguage()}}         
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
            data1="Android + iOS {{'apps' | translate : globals.getLanguage()}}" 
            data2="{{'same' | translate : globals.getLanguage()}}"
            data3="{{'dashboard' | translate : globals.getLanguage()}}"
            data4="{{'push_notifications' | translate : globals.getLanguage()}}"
            data5="{{'priceF' | translate : globals.getLanguage()}}"
            image="../../assets/images/bringfood-apps.jpg">
          </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <a routerLink="/mobile">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            Custom web apps          
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
              data1="{{'mob_responsive' | translate : globals.getLanguage()}}" 
              data2="Angular14 or React"
              data3="{{'var_features' | translate : globals.getLanguage()}}"
              data4="{{'search' | translate : globals.getLanguage()}}"
              data5="{{'priceG' | translate : globals.getLanguage()}}"
              image="../../assets/images/web-app.jpg">
            </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <a routerLink="/mobile">
      <mat-card class="dashboard-card">
        <div class="mat-card-header">
          <div class="mat-card-title">
            Custom mobile apps          
          </div>
        </div>
        <mat-card-content class="dashboard-card-content">
          <app-product 
              data1="Android" 
              data2="iOS"
              data3="{{'push_notifications' | translate : globals.getLanguage()}}"
              data4="Background Geolocation"
              data5="{{'priceG' | translate : globals.getLanguage()}}"
              image="../../assets/images/mobile-app.jpg">            
            </app-product>
        </mat-card-content>
      </mat-card>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
  </div>
  </div>