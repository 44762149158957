import { Injectable } from '@angular/core';

//Should not be a service. It should be in a seperate file so that it is a Singleton and can be shared - set by other componenets

@Injectable({
  providedIn: 'root',
})
export class Globals {    

  private clients: Promise<any>; 
  private language: string = 'el';

  public setClients(x: Promise<any>): void{
    this.clients = (x); 
  }
  
  public getClients(): Promise<any>{
    return this.clients;
  }

  public setLanguage(x: string): void{
    this.language = (x); 
  }
  
  public getLanguage(): string{
    return this.language;
  }

      
}