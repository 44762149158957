import { Injectable, EventEmitter, Output } from '@angular/core';
import { Feathers } from './feathers.service';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class DataService {


  constructor(private feathers: Feathers) {
   
  }

  createMessage(payload){
    this.feathers.service('appdate').create(payload);
  }

}