
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Globals } from '../services/globals';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

  columnNumber: number = 1;
  rowNumber: number= 0.5
  

  constructor(private breakpointObserver: BreakpointObserver,
    public globals: Globals) {}

  ngOnInit() {
    this.columnNumber = (window.innerWidth <= 900) ? 1 : 2;
    this.rowNumber = (window.innerWidth <= 1000 && window.innerWidth > 900) ? 1 : 0.55;
  }

  onResize(event: any) {
    this.columnNumber = (event.target.innerWidth <= 900) ? 1 : 2;
    this.rowNumber = (window.innerWidth <= 1000 && window.innerWidth > 900) ? 1 : 0.55;
  }
  
}
