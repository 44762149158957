import { Component } from '@angular/core';
import { DataService } from '../services/data.service';
import { Globals } from '../services/globals';

const EMAIL_REG_EXP  = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
  selector: 'app-booking-system',
  templateUrl: './booking-system.component.html',
  styleUrls: ['./booking-system.component.scss']
})
export class BookingSystemComponent  {

  email: string = "";

  constructor(private dataService: DataService, public globals: Globals) { }

}

