import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() data1: string = "";
  @Input() data2: string = "";
  @Input() data3: string = "";
  @Input() data4: string = "";
  @Input() data5: string = "";
  @Input() image: string = "";
  @Input() imageWidth: string = "170";

  constructor() { }

  ngOnInit(): void {
  }

}
