
<div class="container-outter">
  <div class="container">
      <div class="heading-section">
          <div class="heading-title">
            <h2 class="font-darkblue">{{'contact_head_title' | translate : globals.getLanguage()}}</h2>
            <p class="font-grey">{{'contact_head_subtitle' | translate : globals.getLanguage()}}</p>
          </div>
      </div>
  </div>
</div>

<div class="container space-bottom">

  <section class="contactus-container">
    <form [formGroup]="messageForm" novalidate (ngSubmit)="onSubmit()">
      <mat-card class="shipping-card">
        <mat-card-content>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'contact_input_1' | translate : globals.getLanguage()}}" formControlName="company">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'contact_input_2' | translate : globals.getLanguage()}}" formControlName="firstName">
                <mat-error *ngIf="messageForm.controls['firstName'].hasError('required')">
                  {{'field_required' | translate : globals.getLanguage()}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'contact_input_3' | translate : globals.getLanguage()}}" formControlName="lastName">
                <mat-error *ngIf="messageForm.controls['lastName'].hasError('required')">
                  {{'field_required' | translate : globals.getLanguage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>     
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'contact_input_4' | translate : globals.getLanguage()}}" formControlName="phone">
                <mat-error *ngIf="messageForm.controls['phone'].hasError('required')">
                  {{'field_required' | translate : globals.getLanguage()}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="{{'contact_input_5' | translate : globals.getLanguage()}}" formControlName="email">
                <mat-error *ngIf="messageForm.controls['email'].hasError('required')">
                  {{'field_required' | translate : globals.getLanguage()}}
                </mat-error>
              </mat-form-field>
            </div>      
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <textarea matInput #Message rows="5" placeholder="{{'contact_input_6' | translate : globals.getLanguage()}}" type="text" formControlName="message"></textarea>
                <mat-hint align="end">{{Message.value.length}} / 700</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="space-top"></div>
              <mat-label>{{'contact_radio_header' | translate : globals.getLanguage()}}</mat-label>
              <mat-radio-group formControlName="interested">
                <mat-radio-button value="eshop">E-shop</mat-radio-button>
                <mat-radio-button value="food">Food Delivery + POS</mat-radio-button>
                <mat-radio-button value="custom">Booking / Rental site</mat-radio-button>
                <mat-radio-button value="custom">{{'contact_custom_app' | translate : globals.getLanguage()}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit" disabled="{{!messageForm.valid}}">{{'contact_submit' | translate : globals.getLanguage()}}</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </section>

</div>
